<template>
  <img :src="logoImage" width="190">
</template>
<script>
export default {
  data() {
    return {
      // eslint-disable-next-line global-require
      logoImage: require('@/assets/images/logo/vergos-new-logo-2022.png'),
    }
  },
}
</script>
